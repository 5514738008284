@font-face {
    font-family: "Podorva";
    src: url("./fonts/Podorva.woff") format("woff"),
    url("./fonts/Podorva.woff") format("woff");
}

* {
    box-sizing: border-box;
    font-family: "Podorva", sans-serif;
    font-weight: 100;
    margin: 0;
    padding: 0;
    text-decoration: none;
}

html {
    background-size: 16%;
    background-color: #181818;
    background-image: url("./images/backgrounds/paper_black.png");
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
}

h3 {
    width: 100%;
    font-size: 180px;
    color: white;
    text-align: right;
}

p {
    color: white;
    font-size: 30px;
}

.not_found_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: center;
    height: 100vh;
    width: 100%;
    background-size: 20%;
    background-image: url("./images/backgrounds/paper.png?v=3.0");
}

.not_found_title {
    text-align: center;
}

h1.not_found_title {
    font-size: 200px;
}

h2.not_found_title {
    font-size: 50px;
}

.filter-green {
    filter: brightness(0) saturate(100%) invert(49%) sepia(80%) saturate(474%) hue-rotate(89deg) brightness(100%) contrast(88%);
}

.filter-red {
    filter: brightness(0) saturate(100%) invert(21%) sepia(96%) saturate(7182%) hue-rotate(358deg) brightness(98%) contrast(116%);
}

.filter-coral {
    filter: brightness(0) saturate(100%) invert(42%) sepia(18%) saturate(3591%) hue-rotate(315deg) brightness(101%) contrast(87%);
}

.filter-blue {
    filter: brightness(0) saturate(100%) invert(28%) sepia(100%) saturate(1323%) hue-rotate(190deg) brightness(87%) contrast(86%);
}

.filter-grey {
    filter: brightness(0) saturate(100%) invert(22%) sepia(26%) saturate(1911%) hue-rotate(211deg) brightness(86%) contrast(91%);
}

.filter-yellow {
    filter: invert(69%) sepia(80%) saturate(994%) hue-rotate(355deg) brightness(98%) contrast(98%);
}

.button:hover .SocialMediaButtonImage, .button:hover p {
    /*filter: invert(69%) sepia(80%) saturate(994%) hue-rotate(355deg) brightness(98%) contrast(98%);*/
    /*filter: brightness(0) saturate(100%) invert(21%) sepia(96%) saturate(7182%) hue-rotate(358deg) brightness(98%) contrast(116%);*/
}

canvas {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}

.App {
    text-align: center;
    min-height: 100vh;
}

.Page {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 150px;
    margin: 0 auto;
    align-self: center;
    width: 100vw;
    height: 100vh;
    /*max-width: 1000px;*/
}

.TitleContainer {
    display: flex;
    flex-direction: column;
    height: 40%;
    width: 100%;
    padding: 50px;
    align-items: center;
    justify-content: center;
    z-index: 4;
    color: #ffffff;
}

.releaseTitle {
    height: 30%;
    padding: 50px 50px 20px 50px;
}

.releaseTitle.osin {
    filter: invert();
}

.Info {
    font-size: calc(12px + 3vmin);
    text-align: center;
    font-weight: 100;
    margin-top: 150px;
    max-width: 1000px;
}

.Title {
    font-size: 20vw;
    letter-spacing: 8px;
    margin-top: 0;
    /*font-weight: 100;*/
}

.logoRound {
    position: relative;
    background-size: contain;
    width: 100%;
    height: 40vh;
    margin-top: 15vh;
    transition: 0.1s;
}

.logoRound img {
    height: 40vh;
    width: 40vh;
    border-radius: 20vh;
    background-color: white;
}

.mainContent {
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    align-items: center;
    height: 100%;
    justify-content: space-around;
}

.blockDescTitle.video {
    font-size: 110px;
    padding-bottom: 25px;
    text-align: center;
}

.blockDescTitleCover {
    display: none;
}

.blockDesc {
    font-size: 3vw;
    text-align: right;
    align-self: flex-end;
    width: 100%;
    padding: 40px 20px 80px 20px;
}

.blockDesc.video {
    text-align: center;
}

.videoFrame {
    border-radius: 4px;
    background-color: #FF4646BF;
    /*-webkit-box-shadow: 0px 0px 20px 0px rgba(255, 70, 70, 0.75);*/
    /*-moz-box-shadow: 0px 0px 20px 0px rgba(255, 70, 70, 0.75);*/
    /*box-shadow: 0px 0px 20px 0px rgba(255, 70, 70, 0.75);*/
}

.audioContainer {
    display: flex;
    flex-direction: column;
}

.spotify {
    /*position: absolute;*/
    width: 100%;
    max-width: 500px;
    align-self: flex-end;
    /*margin-bottom: -70px;*/
    /*height: 100px;*/
}

.musicButtonsContainer {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
}

.musicIcon {
    width: 30px;
    height: 30px;
    filter: invert();
}

.buttonsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    padding-bottom: 100px;
    width: 50%;
}

.releaseBackImg {
    filter: invert();
    position: absolute;
    bottom: 0;
    width: 90%;
    opacity: .2;
}

.buttonsContainer.osin {
    background-size: 30%;
    background-image: url("./images/backgrounds/paper_black.png?v=3.0");
}

.buttonsContainer.lis {
    background-size: 40%;
    background-image: url("./images/backgrounds/lis_seamless_yellow.png?v=3.0");
}

.button {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    font-size: 50px;
    line-height: 80px;
    height: 80px;
    max-height: 80px;
    color: white;
    width: 50%;
    max-width: 380px;
    min-width: 200px;
    justify-content: space-between;
    cursor: pointer;
    transition: .1s ease-in-out;
}

.SocialMediaButton {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    width: 40px;
    height: 40px;
    color: #ffffff;
    font-weight: bold;
    z-index: 1;
}

.SocialMediaButtonImage {
    height: 100%;
}

.cls-1 {
    fill: #0101;
    stroke: #ffff;
    stroke-width: 2;
}

.SocialMediaButtonImage.white {
    filter: invert();
}

.SocialMediaButtonStroke {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    max-height: 200px;
    z-index: 0;
}

.SocialMediaButtonStroke.osin {
    filter: brightness(0) saturate(100%) invert(16%) sepia(96%) saturate(1000%) hue-rotate(358deg) brightness(98%) contrast(116%);
}

/* Albums */

@keyframes floatingAlbum {
    0% {
        transform: rotateX(3deg) rotateY(20deg) rotateZ(10deg)
    }
    20% {
        transform: rotateX(-8deg) rotateY(3deg) rotateZ(-10deg)
    }
    80% {
        transform: rotateX(2deg) rotateY(-20deg) rotateZ(6deg)
    }
    100% {
        transform: rotateX(3deg) rotateY(20deg) rotateZ(10deg)
    }
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}

.albumWrapper {
    width: 100%;
    height: 100%;
    padding-top: 100px;
    border-radius: 12px;
}

/*.albumWrapper.yellow {*/
/*  background-color: #481b00;*/
/*}*/

.albumTopBlock {
    display: flex;
    /*position: relative;*/
}

.albumInfoBlock {
    position: relative;
    padding: 20px;
    border-radius: 12px;
    width: 100%;
}

.backgroundImage {
    position: absolute;
    bottom: 0;
    width: 50%;
}

.backgroundImage.brown {
    opacity: 0.4;
    filter: brightness(0) saturate(100%) invert(16%) sepia(82%) saturate(1784%) hue-rotate(13deg) brightness(95%) contrast(101%);
}

.backgroundImage.white {
  filter: brightness(0) saturate(100%) invert(22%) sepia(10%) saturate(15%) hue-rotate(6deg) brightness(97%) contrast(78%);
}

.Page.yellow {
    background-color: rgba(72, 27, 0, 0.5);
}

.Page.red {
    background-size: 16%;
    background-image: url("./images/backgrounds/paper_red.jpg");
}

.Page.black {
    background-color: rgba(0, 0, 0, 0.5);
}

.albumInfoBlock.yellow {
    /*background-color: rgba(72, 27, 0, 0.5);*/
}

.albumInfoBlock.black {
    /*background-color: rgba(0, 0, 0, 0.5);*/
}

.albumCoverBlock {
    position: relative;
    display: flex;
    align-items: center;
}

.albumCover {
    position: relative;
    animation: floatingAlbum 15s infinite;
    width: 20vw;
    height: 20vw;
    transition: .1s;
    z-index: 20;
    -webkit-box-shadow: 5px 5px 20px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 5px 5px 20px 0px rgba(0,0,0,0.75);
    box-shadow: 5px 5px 20px 0px rgba(0,0,0,0.75);
}

.coverShadow {
    animation: floatingAlbum 15s infinite;
    position: absolute;
    display: block;
    width: 20vw;
    height: 20vw;
    background-color: rgba(0, 0, 0, 0.2);
    filter: blur(10px);
    transition: .1s;
    z-index: 10;
}

.albumCover:nth-of-type(2) {
    animation-delay: -2s;
}

.coverShadow:nth-of-type(2) {
    animation-delay: -2s;
}

.albumCover:hover {
    /*animation: shake 0.2s cubic-bezier(.36, .07, .19, .97) both;*/
    /*transform: translate3d(0, 0, 0);*/
    /*backface-visibility: hidden;*/
    /*perspective: 1000px;*/
    /*width: 20vw;*/
    /*height: 20vw;*/
}

.albumCover:hover::after {
    animation: shake 0.2s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
    width: 20vw;
    height: 20vw;
    /*top: 20px;*/
    filter: blur(35px);
}

.Footer {
    position: absolute;
    bottom: 10px;
    right: 50px;
    padding: 25px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 6;
    opacity: 0.6;
}

@media screen and (max-width: 2000px) {
    .Title {
        font-size: 16vw;
    }

    .Info {
        margin-top: 40px;
    }
}

@media screen and (max-width: 1000px) {
    .Page {
        padding: 0 50px;
    }
    .blockDesc {
        font-size: 5vw;
        padding: 40px 20px 80px 20px;
    }
    .Footer {
        flex-direction: row;
        justify-content: space-around;
        gap: 0;
        right: 0;
        left: 0;
    }
    .SocialMediaButton {
        width: 8vw;
        height: 8vw;
    }

    .SocialMediaButtonImage {
        height: 70%;
    }
}

@media screen and (max-width: 850px) {
    .SocialMediaButtonImage {
        height: 80%;
    }

    .videoFrame {
        width: 80vw;
        height: calc((9 / 16) * 80vw);
    }

    .spotify {
        width: 50vw;
        /*height: calc((9 / 16) * 80vw);*/
    }
}

@media screen and (max-width: 700px) {
    .TitleContainer {
        height: 20%;
    }

    .Page {
        padding: 0 20px;
    }

    .button {
        font-size: 40px;
        line-height: 60px;
        height: 80px;
    }
}

@media screen and (max-width: 650px) {
    .albumTopBlock {
        flex-direction: column;
        height: 100%;
    }
    .albumCoverBlock {
        justify-content: center;
    }
    .albumCover, .coverShadow {
        width: 150px;
        height: 150px;
    }
    .albumInfoBlock {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-around;
    }
    .mainContent {
        padding-top: 25px;
    }
    h3.blockDescTitle.video {
        font-size: 100px;
        text-align: center;
    }
    .blockDescTitle:not(.video) {
        display: none;
    }
    .blockDescTitleCover {
        /* TODO: Album title for mobile */
        display: none;
        position: absolute;
        text-align: center;
        text-shadow: 0 6px 11px rgba(0,0,0,1);
        z-index: 20;
    }
    .blockDesc {
        font-size: 4vw;
        padding: 20px;
        text-align: center;
    }
    .musicButtonsContainer {
        justify-content: space-around;
    }
    .spotify {
        width: 100%;
    }
}

@media screen and (max-width: 540px) {
    .Title {
        font-size: 20vw;
    }
    .TitleContainer .Info {
        max-width: 300px;
        font-size: 24px;
    }
    .blockDesc {
        font-size: 6vw;
        padding: 30px 20px;
    }

    .Footer {
        justify-content: space-around;
        z-index: 10;
    }
    .SocialMediaButton {
        width: 12vw;
        height: 12vw;
    }
}

@media screen and (max-width: 450px) {
    html {
        background-size: 30%;
    }
    .Page {
        height: 100svh;
    }
    .Page.one {
        height: 100dvh;
    }
    .TitleContainer {
        font-size: calc(30px + 5vmin);
    }

    .releaseTitle {
        height: 24%;
    }

    .SocialMediaButtonImage {
        height: 80%;
    }
}

@media screen and (max-width: 420px) {
    /*.Title {*/
    /*  font-size: calc(60px + 5vmin);*/
    /*}*/
    /*.Page {*/
    /*  height: 100vh;*/
    /*}*/
    .TitleContainer {
        padding: 10px;
    }

    .Info {
        margin-top: 10px;
    }

    .buttonsContainer {
        padding-bottom: 150px;
    }
}

@media screen and (max-width: 375px) {
    .button {
        font-size: 30px;
    }

    .logoRound {
        height: 40vh;
        margin-top: 10vh;
    }

    .logoRound img {
        height: 40vh;
        width: 40vh;
        border-radius: 20vh;
    }

    .logoRound .circle {
        margin-top: -1.5vh;
    }

    .logoRound .circle.two {
        margin-top: 6vh;
    }

    /*.TitleContainer {*/
    /*  padding: 20px 10px 150px 10px;*/
    /*}*/
    /*.TitleContainer .Title {*/
    /*  font-size: calc(60px + 5vmin);*/
    /*}*/
    /*.TitleContainer .Info {*/
    /*  font-size: calc(20px + 2vmin);*/
    /*}*/
    .Footer {
        height: auto;
        padding: 10px 0 25px 0;
    }
}

@media screen and (max-width: 300px) {
    /*.TitleContainer .Title {*/
    /*  font-size: 60px;*/
    /*}*/
}

@media screen and (max-height: 700px) {
    .logoRound {
        margin-top: 5vh;
    }
}

@media screen and (max-height: 600px) {
    /*.TitleContainer {*/
    /*  padding: 0 10px 25px 10px;*/
    /*}*/
    /*.Title {*/
    /*  font-size: calc(50px + 5vmin);*/
    /*}*/
    /*.Footer {*/
    /*  height: auto;*/
    /*  padding: 10px 0 25px 0;*/
    /*}*/
}
